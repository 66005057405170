/* Copyright (C) 2019-2022 Greenbone Networks GmbH
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Affero General Public License
 * as published by the Free Software Foundation, either version 3
 * of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */
import {_} from 'gmp/locale/lang';

import {isDefined} from 'gmp/utils/identity';

import Rejection from 'gmp/http//rejection';

export const success = transform => (response, options = {}) => {
  try {
    return transform(response);
  } catch (error) {
    throw new Rejection(
      response.xhr,
      Rejection.REASON_ERROR,
      _(
        'An error occurred while converting gmp response to js for ' +
          'url {{- url}}',
        {url: options.url},
      ),
      error,
    );
  }
};

export const rejection = transform => (rej, options) => {
  if (rej.isError && rej.isError()) {
    const data = transform(rej);
    if (!isDefined(data)) {
      return rej;
    }

    const {envelope} = data;
    if (isDefined(envelope)) {
      rej.root = envelope;

      if (isDefined(envelope.gsad_response)) {
        return rej.setMessage(envelope.gsad_response.message);
      }

      if (isDefined(envelope.action_result)) {
        return rej.setMessage(envelope.action_result.message);
      }
    }

    return rej;
  }

  return rej;
};
